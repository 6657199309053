export const { REACT_APP_API_ENDPOINT, REACT_APP_FILE_ENCRYPT_SECRET } =
  process.env;
//login
export const LOGIN_URL = `${REACT_APP_API_ENDPOINT}auth/login`;
export const LOGOUT_URL = `${REACT_APP_API_ENDPOINT}auth/logout`;
export const GET_USER = `${REACT_APP_API_ENDPOINT}auth/current`;
export const FORGET_PASSWORD = `${REACT_APP_API_ENDPOINT}auth/forgot/`;
export const FORGOT_PASSWORD = `${REACT_APP_API_ENDPOINT}auth/forgot/`;
export const FORGOT_PASSWORD_VERIFY_TOKEN = `${REACT_APP_API_ENDPOINT}auth/verify/forgot/`;
export const SET_PASSWORD_VERIFY_TOKEN = `${REACT_APP_API_ENDPOINT}auth/verify/set/`;
export const CONFIRM_PASSWORD = `${REACT_APP_API_ENDPOINT}auth/confirm/`;
export const RESET_PASSWORD = `${REACT_APP_API_ENDPOINT}auth/reset/`;
export const CHANGE_PASSWORD = `${REACT_APP_API_ENDPOINT}auth/reset`;

//master
export const REGIONS = `${REACT_APP_API_ENDPOINT}master/region/list`;
export const REGIONCOUNTRIES = `${REACT_APP_API_ENDPOINT}master/region`;
export const COUNTRIES = `${REACT_APP_API_ENDPOINT}master/country/list`;
export const STATES = `${REACT_APP_API_ENDPOINT}master/country`;
export const CATEGORIES = `${REACT_APP_API_ENDPOINT}master/categories`;
export const CGF_OFFICES = `${REACT_APP_API_ENDPOINT}master/offices`;
export const OPERATION_TYPES = `${REACT_APP_API_ENDPOINT}master/operationTypes`;
export const MASTER_LINK = `${REACT_APP_API_ENDPOINT}master/link`;

//Member
export const MEMBER = `${REACT_APP_API_ENDPOINT}members`;
export const PENDING_MEMBER = `${REACT_APP_API_ENDPOINT}members/pending`;

export const MEMBER_DROPDOWN = `${REACT_APP_API_ENDPOINT}members/master`;
export const SPECIFIC_MEMBER_DROPDOWN = `${REACT_APP_API_ENDPOINT}members/master/`;
export const PARENT_COMPINES = `${REACT_APP_API_ENDPOINT}members/parentCompanies/master`;
export const WITHDRAW_MEMBER_INVITE = `${REACT_APP_API_ENDPOINT}auth/invite/`;
export const CITES = `${REACT_APP_API_ENDPOINT}members/cities/master`;

//Operation member
export const MEMBER_OPERATION_MEMBERS = `${REACT_APP_API_ENDPOINT}operation-member/member`;

//sub admin
export const FETCH_ROLES = `${REACT_APP_API_ENDPOINT}roles/master`;
export const ADD_SUB_ADMIN = `${REACT_APP_API_ENDPOINT}users/cgfadmin`;
export const FETCH_SUB_ADMIN_BY_ADMIN = `${REACT_APP_API_ENDPOINT}users/cgfadmin/`;
export const FETCH_PENDING_CGF_ADMIN = `${REACT_APP_API_ENDPOINT}users/cgfadmin/pending/`;
export const UPDATE_SUB_ADMIN = `${REACT_APP_API_ENDPOINT}users/cgfadmin/`;
export const UPDATE_PENDING_CGF_ADMIN = `${REACT_APP_API_ENDPOINT}users/cgfadmin/pending/`;
export const WITHDRAW_SUB_ADMIN = `${REACT_APP_API_ENDPOINT}auth/invite/`;
export const DELETE_SUB_ADMIN = `${REACT_APP_API_ENDPOINT}users/cgfadmin/`;
export const REPLACE_SUB_ADMIN = `${REACT_APP_API_ENDPOINT}positions/mappings/`;

// add operationn member
export const ADD_OPERATION_MEMBER = `${REACT_APP_API_ENDPOINT}operation-member`;
export const FETCH_OPERATION_MEMBER = `${REACT_APP_API_ENDPOINT}operation-member/member/`;
export const FETCH_PENDING_OPERATION_MEMBER = `${REACT_APP_API_ENDPOINT}operation-member/pending/`;
export const WITHDRAW_OPERATION_MEMBER = `${REACT_APP_API_ENDPOINT}auth/invite/`;
export const DELETE_OPERATION_MEMBER = `${REACT_APP_API_ENDPOINT}operation-member/`;
export const GET_OPERATION_MEMBER_BY_ID = `${REACT_APP_API_ENDPOINT}operation-member/`;
export const UPDATE_OPERATION_MEMBER = `${REACT_APP_API_ENDPOINT}operation-member/`;
export const UPDATE_PENDING_OPERATION_MEMBER = `${REACT_APP_API_ENDPOINT}operation-member/pending/`;
export const FETCH_REPORTING_MANAGER = `${REACT_APP_API_ENDPOINT}operation-member/member/`;
export const REPLACE_OPERATION_MEMBER = `${REACT_APP_API_ENDPOINT}positions/mappings/`;

// add questionnaire
export const ADD_QUESTIONNAIRE = `${REACT_APP_API_ENDPOINT}questionnaires`;
export const DELETE_QUESTIONNAIRE_DRAFT = `${REACT_APP_API_ENDPOINT}questionnaires/delete`;

// assessments
export const FETCH_ASSESSMENT_BY_ID = `${REACT_APP_API_ENDPOINT}assessments/`;
export const UPDATE_ASSESSMENT_BY_ID = `${REACT_APP_API_ENDPOINT}assessments/`;
export const SUBMIT_ASSESSMENT_AS_DRAFT = `${REACT_APP_API_ENDPOINT}assessments/`;
export const ASSESSMENTS = `${REACT_APP_API_ENDPOINT}assessments/list/`;
export const ADD_ASSESSMENTS = `${REACT_APP_API_ENDPOINT}assessments/`;
export const REASSIGN_ASSESSMENTS = `${REACT_APP_API_ENDPOINT}assessments/`;
export const ACCEPT_ASSESSMENT = `${REACT_APP_API_ENDPOINT}assessments/`;
export const DECLINE_ASSESSMENT = `${REACT_APP_API_ENDPOINT}assessments/`;
export const UPLOAD_ATTACHMENTS = `${REACT_APP_API_ENDPOINT}assessments/upload/files`;
export const UPLOAD_ATTACHMENTS_MULTER = `${REACT_APP_API_ENDPOINT}assessments`;
export const IMPORT_ASSESSMENT = `${REACT_APP_API_ENDPOINT}assessments/`;

// download
export const DOWNLOAD_CGF_ADMIN = `${REACT_APP_API_ENDPOINT}users/cgfadmin/list/download`;
export const DOWNLOAD_MEMBERS = `${REACT_APP_API_ENDPOINT}members/list/download`;
export const DOWNLOAD_OPERATION_MEMBER = `${REACT_APP_API_ENDPOINT}operation-member/list/download`;
export const DOWNLOAD_ASSESSMENT = `${REACT_APP_API_ENDPOINT}assessments/list/download`;
export const DOWNLOAD_ASSESSMENT_BY_ID = `${REACT_APP_API_ENDPOINT}assessments/`;
export const DOWNLOAD_QUESTIONNAIRES = `${REACT_APP_API_ENDPOINT}questionnaires/list/download`;
export const DOWNLOAD_QUESTIONNAIRES_BY_ID = `${REACT_APP_API_ENDPOINT}questionnaires/`;
//roles
export const VIEW_ROLE = `${REACT_APP_API_ENDPOINT}roles/`;
// roles
export const ROLE_BY_ID = `${REACT_APP_API_ENDPOINT}roles/`;
export const GET_ROLE_BY_ID = `${REACT_APP_API_ENDPOINT}roles/`;
export const GET_USER_BY_ROLE = `${REACT_APP_API_ENDPOINT}roles/`;

// show Downloaded Files
export const DOWNlOAD_FILE = `${REACT_APP_API_ENDPOINT}assessments/`;

// Resend invite
export const RESEND_INVITE = `${REACT_APP_API_ENDPOINT}auth/invite/resend/`;

// Dashboard
export const DASHBOARD  =`${REACT_APP_API_ENDPOINT}dashboard`;
export const COMPANY_SAQ_STATUS = `${REACT_APP_API_ENDPOINT}dashboard/saq/company?memberId=`
export const COUNTRY_SAQ_STATUS = `${REACT_APP_API_ENDPOINT}dashboard/saq/country?country=`
export const TOTAL_WORKERS=`${REACT_APP_API_ENDPOINT}dashboard/doughnut`
// download Assessment
export const DOWNLOAD_ACTION_PLAN = `${REACT_APP_API_ENDPOINT}assessments/`

// other document
export const UPLOAD_OTHER_DOC=`${REACT_APP_API_ENDPOINT}questionnaires/other-documents`
export const DOWNLOAD_OTHER_DOCS=`${REACT_APP_API_ENDPOINT}questionnaires/other-documents/download`
export const DELETE_OTHER_DOCS=`${REACT_APP_API_ENDPOINT}questionnaires/other-documents`

// file download
export const ZIP_FILE_DOWNLOAD = `${REACT_APP_API_ENDPOINT}assessments/export/assessments?search=`
// rule engine
export const FETCH_RULE_ENGINE_OBJECT = `${REACT_APP_API_ENDPOINT}dashboard/rules/`
export const UPDATE_RULE_ENGINE_OBJECT = `${REACT_APP_API_ENDPOINT}dashboard/rules/`